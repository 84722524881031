import React from "react";
import "./CoachSection.css";


const coaches = [
  {
    name: "HSAM",
    description:
      "Atualmente na TBS Game Changers, onde classificou a equipe para o presencial do VCT Game Changers 2024, e chegou a treinar a STELLAE Gaming ajudando a classificar para o VCB 2024.",
    image: "/images/HSAM.png", // Substitua pelo link ou caminho da imagem
  },
  {
    name: "FLSH",
    description:
      "Atualmente na TBS Game Changers, já chegou a treinar a ODDIK, TEAM LEVEL UP e a LOUD Game Changers, onde conseguiu levar a equipe a chegar ao TOP 2 no Game Changers.",
    image: "/images/FLSH.png", // Substitua pelo link ou caminho da imagem
  },
];

const CoachSection = () => {
  return (
    <section className="coach-section">
      <h2 className="h2">APRENDA COM OS MELHORES</h2>
      <div className="coach-cards">
        {coaches.map((coach, index) => (
          <div className="coach-card" key={index}>
            <img src={coach.image} alt={coach.name} className="coach-image" />
            <h3>{coach.name}</h3>
            <p>{coach.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CoachSection;
