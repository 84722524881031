import React, { useEffect } from "react";
import './App.css';
import Header from "./components/Header.js";
import Card from "./components/Card.js";
import CoachSection from "./components/CoachSection";
import DominusInfo from "./components/DominusInfo.js";
import PromoBanner from "./components/PromoBanner.js";
import Faqs from "./components/Faqs.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "./components/Footer.js";
import Reviews from "./components/Review.js";
import ReactPixel from 'react-facebook-pixel';

// Importação do AOS
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    // Inicializando o Facebook Pixel
    const PIXEL_ID = '1095086388599162';  // Substitua pelo seu Pixel ID
    const options = {
      autoConfig: true,  // Configura o Pixel automaticamente
      debug: false,      // Desativa o modo de debug
    };

    // Inicializando o Facebook Pixel
    ReactPixel.init(PIXEL_ID, options);
    ReactPixel.pageView(); // Registra a visualização da página

    // Inicializando o AOS (Animações)
    AOS.init({
      duration: 1200, // Duração da animação em milissegundos
      offset: 200,    // Distância de ativação
      easing: "ease-in-out", // Tipo de animação
      once: true,     // Animação acontece apenas uma vez
    });
  }, []);  // A dependência vazia [] garante que isso aconteça apenas uma vez

  return (
    <>
      <Header />
      <div>
        {/* Adicionando animações aos componentes */}
        <div data-aos="fade-up">
          <Card />
        </div>
        <div data-aos="zoom-in">
          <PromoBanner />
        </div>
        <div data-aos="fade-up">
          <CoachSection />
        </div>
        <div data-aos="fade-up">
          <Reviews />
        </div>
        <div data-aos="zoom-in">
          <DominusInfo />
        </div>
        <div data-aos="fade-up">
          <Faqs />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
