import React from "react";
import "./DominusInfo.css";

const DominusInfo = () => {
  return (
    <div className="dominus-container">
      <h1 className="dominus-title">CONHEÇA A DOMINUS</h1>
      <div className="dominus-cards">
        <div className="dominus-card">
          <h2 className="dominus-card-title">SOBRE NÓS</h2>
          <p className="dominus-card-content">
            Somos uma equipe formada por ex-profissionais e profissionais que atuam no cenário competitivo, incluindo participantes de campeonatos oficiais da Riot Games, como o VCT Game Changers e o VALORANT Challengers Brazil, com uma única missão: ensinar e capacitar jogadores de todos os níveis.
          </p>
          <p className="dominus-card-content">
            Na Dominus, acreditamos que todo jogador tem potencial para evoluir, independentemente de sua experiência ou objetivo no VALORANT. Seja você alguém que sonha em competir profissionalmente ou apenas quer melhorar suas habilidades para subir de elo mais rápido, estamos aqui para ajudar. Oferecemos conteúdos e treinamentos abrangentes, cobrindo desde as mecânicas básicas até estratégias avançadas.
          </p>
        </div>
        <div className="dominus-card">
          <h2 className="dominus-card-title">NOSSA VISÃO</h2>
          <p className="dominus-card-content">
            Imaginamos um futuro onde jogadores de Valorant em todo o Brasil tenham acesso a um treinamento que realmente faz a diferença.
          </p>
          <p className="dominus-card-content">
            Onde qualquer pessoa, independentemente do elo ou da experiência, possa transformar seu potencial em resultados concretos.
          </p>
          <p className="dominus-card-content">
            Nossa visão é criar uma comunidade de jogadores que não apenas evoluam individualmente, mas que dominem o jogo, inspirem outros e sejam reconhecidos como referências – desde as ranqueadas até o cenário profissional.
          </p>
        </div>
        <div className="dominus-card">
          <h2 className="dominus-card-title">NOSSA MISSÃO</h2>
          <p className="dominus-card-content">
            Na Dominus Academy, nossa missão é clara: ajudar você a se tornar o jogador que sempre sonhou ser.
          </p>
          <p className="dominus-card-content">
            Com métodos testados por profissionais e estratégias que realmente funcionam, queremos guiar jogadores em uma jornada de transformação.
          </p>
          <p className="dominus-card-content">
            Desde quem está começando até aqueles que almejam o topo, estamos aqui para entregar um sistema simples, direto e prático, que vai te levar do básico ao avançado.
          </p>
          <p className="dominus-card-content">
            Nosso objetivo é te dar todas as ferramentas necessárias para dominar o jogo e se destacar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DominusInfo;
