import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactPixel from 'react-facebook-pixel';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>

);

// Inicializar o Facebook Pixel
const PIXEL_ID = '1095086388599162';  // Substitua pelo ID do seu Pixel

const options = {
  autoConfig: true,  // Configura o Pixel automaticamente
  debug: false,      // Define se deseja depurar no console
};

ReactPixel.init(PIXEL_ID, options);
ReactPixel.pageView(); // Registra a visita à página

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
