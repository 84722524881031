import React from "react";
import "./Reviews.css"; // Certifique-se de criar este arquivo para os estilos

const reviews = [
  {
    name: "João Silva",
    role: "Membro Dominus",
    review: "Subi dois elos em poucas semanas graças ao curso. As explicações são claras e fáceis de aplicar.",
  },
  {
    name: "Maria Oliveira",
    role: "Membro Dominus",
    review: "Mano, esse curso é brabo demais! Subi de elo rapidão e agora tô jogando muito mais confiante.",
  },
  {
    name: "Carlos Eduardo",
    role: "Membro Dominus",
    review: "Tava travado no ouro mas ja com algumas aulas já to platina 2, valeu muito a pena!",
  },
  {
    name: "Ana Beatriz",
    role: "Membro Dominus",
    review:
      "Nunca joguei tão bem! As dicas são top e eu como jogo muito em time já comecei a dar dica pra geral e ajudou eles também.",
  },
  {
    name: "Felipe Santos",
    role: "Membro Dominus",
    review:
      "Muito top que dois coachs do cenário tiram um pouco do seu tempo para dar uma atenção pra geral do curso, recomendo mto.",
  },
  {
    name: "Larissa Costa",
    role: "Membro Dominus",
    review: "As aulas são bem de boa de entender, e as dicas realmente funcionam. Venha ser Dominus!",
  },
];

const Reviews = () => {
  return (
    <div className="reviews-container">
      <h2>CONHEÇA ALGUMAS DAS HISTÓRIAS DE QUEM USOU E EVOLUIU</h2>
      <div className="reviews-grid">
        {reviews.map((item, index) => (
          <div key={index} className="review-card">
            <div className="stars">
              {"★".repeat(5).split("").map((star, i) => (
                <span key={i} className="star-purple">
                  {star}
                </span>
              ))}
            </div>
            <p className="review-text">{item.review}</p>
            <div className="review-author-container">
            <p className="review-author">
              <strong>{item.name}</strong>
            </p>
            <p className="review-role">{item.role}</p>
          </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
