import React from "react";
import "./Footer.css"; // Certifique-se de criar este arquivo para os estilos
import logo from "./logo.png";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-left">
        <img src={logo} alt="Logo Dominus Academy" className="footer-logo" />
        <p>
          © 2024 DOMINUS ACADEMY<br />
          Todos os direitos reservados.<br />
          Desenvolvido por{" "}
          <a
            href="https://instagram.com/rgtechin"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            RG Tech
          </a>
        </p>
      </div>
      <div className="footer-right">
        <a
          href="https://wa.me/5597996266625"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="WhatsApp"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
        <a
          href="https://instagram.com/dominusacademy.gg"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          <i className="fab fa-x-twitter"></i>
        </a>
        <a
          href="https://tiktok.com/@dominusacademy"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="TikTok"
        >
          <i className="fab fa-tiktok"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
